import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PageContainer, ProDescriptions } from "@ant-design/pro-components";
import dayjs from "dayjs";
import { Button, Card, Image } from "antd";
import { getGameSettingsDetail } from "../../../api";

const GameSettingsDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [gameSettingData, setGameSettingData] = useState();

  const fetchGameSettingDetail = useCallback(async () => {
    try {
      const response = await getGameSettingsDetail(id);

      setGameSettingData(response);
    } catch (error) {
      console.log("Error", error);
    }
  }, [id]);

  useEffect(() => {
    fetchGameSettingDetail();
  }, [fetchGameSettingDetail]);

  return (
    <>
      {gameSettingData && (
        <PageContainer
          title={`Game setting details`}
          extra={[
            <Button
              key="edit-waiting-room"
              type="primary"
              onClick={() => navigate(`edit`)}
            >
              Edit Game Setting
            </Button>,
          ]}
        >
          <div className="game-settings">
            <Card title="Game Setting Information">
              <ProDescriptions column={1}>
                <ProDescriptions.Item
                  label="Game Setting ID"
                  valueType="text"
                  span={1}
                  contentStyle={{
                    maxWidth: "80%",
                  }}
                >
                  {gameSettingData?.id}
                </ProDescriptions.Item>
                <ProDescriptions.Item label="Game Type" valueType="text">
                  {gameSettingData?.gameType}
                </ProDescriptions.Item>
                <ProDescriptions.Item label="Is Enabled" valueType="text">
                  {gameSettingData?.isEnabled ? "True" : "False"}
                </ProDescriptions.Item>
                <ProDescriptions.Item label="Max Points" valueType="number">
                  {gameSettingData?.maxPoints}
                </ProDescriptions.Item>
                <ProDescriptions.Item label="Min Level" valueType="number">
                  {gameSettingData?.minLevel}
                </ProDescriptions.Item>
                <ProDescriptions.Item
                  label="Points Per Level"
                  valueType="number"
                >
                  {gameSettingData?.pointsPerLevel}
                </ProDescriptions.Item>

                <ProDescriptions.Item label="Created at">
                  {dayjs(gameSettingData?.createdAt).format("YYYY-MM-DD HH:mm")}
                </ProDescriptions.Item>
              </ProDescriptions>
            </Card>

            <Card title="Eligibility">
              <ProDescriptions column={1}>
                <ProDescriptions.Item
                  label="Cool Down Minutes"
                  valueType="number"
                >
                  {gameSettingData?.eligibility?.cooldownMinutes}
                </ProDescriptions.Item>
                <ProDescriptions.Item label="Order Statuses" valueType="text">
                  {gameSettingData?.eligibility?.orderStatuses &&
                  Array.isArray(gameSettingData?.eligibility?.orderStatuses)
                    ? gameSettingData?.eligibility?.orderStatuses.join(", ")
                    : "--"}
                </ProDescriptions.Item>
              </ProDescriptions>
            </Card>

            <Card title="Config">
              <ProDescriptions column={1}>
                <ProDescriptions.Item label="Thumbnail">
                  <Image width={300} src={gameSettingData?.config?.thumb} />
                </ProDescriptions.Item>
                <ProDescriptions.Item label="Url" valueType="text">
                  {gameSettingData?.config?.url}
                </ProDescriptions.Item>
              </ProDescriptions>
            </Card>
          </div>
        </PageContainer>
      )}
    </>
  );
};

export default GameSettingsDetail;
