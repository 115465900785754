import {
  ProForm,
  ProFormText,
  PageContainer,
  ProFormDigit,
  ProFormSwitch,
  ProFormSelect,
  ProFormTreeSelect,
} from "@ant-design/pro-components";
import { useNavigate } from "react-router";
import { notification } from "antd";
import { useForm } from "antd/lib/form/Form";
import { postGameSetting } from "../../../api";
import { GAME_TYPE_LIST, SHUKAH_ORDER_STATUSES } from "../../../utils/constant";
import ImageUpload from "../../../components/UploadImage";
import { useState } from "react";

const NewGameSettings = () => {
  const [images, setImages] = useState({
    thumb: null,
  });

  const navigate = useNavigate();
  const [form] = useForm();

  const handleSetImage = (value, key, callBack) => {
    form.setFieldValue(key, value);

    callBack((prevState) => ({ ...prevState, [key]: value.url }));
    form.validateFields([key]);
  };

  const handleSubmit = async (values) => {
    try {
      const newParams = {
        gameType: values.gameType,
        isEnabled: values.isEnabled,
        pointsPerLevel: values.pointsPerLevel,
        maxPoints: values.maxPoints,
        minLevel: values.minLevel,
        description: values.description,
        titleEn: values.titleEn,
        titleAr: values.titleAr,
        config: {
          thumb: images?.thumb ?? values.thumb,
          url: values.url,
        },
        eligibility: {
          orderStatuses: values.orderStatuses,
          cooldownMinutes: values.cooldownMinutes,
        },
      };

      const response = await postGameSetting(newParams);

      if (response.status === 201) {
        notification.success({ message: "Create Game Setting successful" });
        navigate("/game-settings");
      }
    } catch (error) {
      if (error.status === 400) {
        notification.error({
          message: error.response.data.message.map((item) => item),
        });
      } else notification.error({ message: "Create Game Setting failed" });
    }
  };

  return (
    <PageContainer title="Create Game Setting">
      <ProForm
        form={form}
        name="createGameSettingForm"
        layout="horizontal"
        onFinish={handleSubmit}
        className="create-event-form event-form"
      >
        <ProForm.Group>
          <div className="form-fields-wrapper">
            <ProFormSelect
              tooltip="There can only be 1 Setting for a Game Type"
              name="gameType"
              label="Game Name"
              width={"lg"}
              placeholder="Select Game Type"
              request={async () => GAME_TYPE_LIST}
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Game Type is required! My friends
                    </p>
                  ),
                },
              ]}
            />

            <ProFormText
              name="titleAr"
              width={"lg"}
              label="Title Ar"
              placeholder="Enter Title Ar"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Title Ar is required! My friends
                    </p>
                  ),
                },
              ]}
            />
            <ProFormText
              name="titleEn"
              width={"lg"}
              label="Title En"
              placeholder="Enter Title En"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Title En is required! My friends
                    </p>
                  ),
                },
              ]}
            />

            <ProFormText
              name="description"
              width={"lg"}
              label="Game Setting Description"
              placeholder="Enter Game Setting Description"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Game Setting Description Ar is required! My friends
                    </p>
                  ),
                },
              ]}
            />

            <ProFormDigit
              name="maxPoints"
              label="Points Amount"
              placeholder="Enter Max Points"
              tooltip="Maximum amount of points can achieved in 1 game session"
              width={"lg"}
              min={0}
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Max Points is required! My friends
                    </p>
                  ),
                },
              ]}
              fieldProps={{ controls: false }}
            />

            <ProFormDigit
              name="minLevel"
              label="Minimum Level To Earn"
              placeholder="Enter Min Level"
              tooltip="Minimum level required to earn points"
              width={"lg"}
              min={0}
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Min Level is required! My friends
                    </p>
                  ),
                },
              ]}
              fieldProps={{ controls: false }}
            />

            <ProFormDigit
              name="pointsPerLevel"
              label="Points Per Level"
              tooltip="Points achieved after minimum level passed"
              placeholder="Enter Points Per Level"
              width={"lg"}
              min={0}
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Points Per Level is required! My friends
                    </p>
                  ),
                },
              ]}
              fieldProps={{ controls: false }}
            />

            <ProFormDigit
              name="cooldownMinutes"
              label="Cool Down Minutes"
              placeholder="Enter Cool Down Minutes"
              width={"lg"}
              min={0}
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Cool Down Minutes is required! My friends
                    </p>
                  ),
                },
              ]}
              fieldProps={{ controls: false }}
            />

            <ProFormTreeSelect
              initialValue={[]}
              width={"lg"}
              name="orderStatuses"
              label="Order Statuses Applied"
              placeholder="Select Order Statuses"
              allowClear
              secondary
              request={async () => {
                return SHUKAH_ORDER_STATUSES;
              }}
              // tree-select args
              fieldProps={{
                suffixIcon: null,
                filterTreeNode: true,
                showSearch: true,
                popupMatchSelectWidth: false,
                labelInValue: false,
                autoClearSearchValue: true,
                multiple: true,
                treeNodeFilterProp: "title",
                fieldNames: {
                  label: "title",
                },
              }}
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Order Statuses is required! My friends
                    </p>
                  ),
                },
              ]}
            />

            <ProFormText
              name="url"
              width={"lg"}
              label="Game Url"
              placeholder="Enter Game Url"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Game Url is required! My friends
                    </p>
                  ),
                },
              ]}
            />
          </div>
        </ProForm.Group>

        <ProForm.Item
          label="Thumbnail Image:"
          name="thumb"
          width="lg"
          rules={[
            {
              required: true,
              message: () => (
                <p className="error-message">
                  Thumbnail image is required! My friends
                </p>
              ),
            },
          ]}
        >
          <ImageUpload
            callBackGetUrl={(value) =>
              handleSetImage(value, "thumb", setImages)
            }
          />
        </ProForm.Item>
        <ProFormSwitch
          name="isEnabled"
          label="Enable Status"
          initialValue={false}
        />
      </ProForm>
    </PageContainer>
  );
};

export default NewGameSettings;
