import { Button } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useForm } from "antd/lib/form/Form";
import { createQueryStringUrl } from "../../utils/createQueryStringUrl";
import { PageContainer, ProTable } from "@ant-design/pro-components";
import { PAGINATION_INITIAL_VALUES } from "../../utils/constant";
import { getGameSettingsList } from "../../api";
import { useGameSettingsColumn } from "../../components/Columns/GameSettings";

const GameSettings = () => {
  const navigate = useNavigate();
  const [form] = useForm();

  const [pagination, setPagination] = useState(PAGINATION_INITIAL_VALUES);
  const [gameSettings, setGameSettings] = useState([]);
  const [filterValues, setFilterValues] = useState({});

  const fetchGameSettingsList = useCallback(async () => {
    try {
      createQueryStringUrl(filterValues);
      form.setFieldsValue(filterValues);

      const params = {
        ...filterValues,
        page: pagination.currentPage,
        limit: pagination.pageSize,
      };

      const response = await getGameSettingsList(params);

      setGameSettings(response.results);
      setPagination((prevState) => ({
        ...prevState,
        totalItems: response.total,
      }));
    } catch (error) {
      console.log("Error", error);
    }
  }, [filterValues, form, pagination.currentPage, pagination.pageSize]);

  const handleSubmitFilter = (params) => {
    const formValues = form.getFieldsValue();

    setFilterValues({
      ...filterValues,
      ...formValues,
    });

    setPagination((prevState) => ({
      ...prevState,
      currentPage: 1,
    }));
  };

  const handleResetFilter = () => {
    setFilterValues({});
    setPagination((prevState) => ({
      ...prevState,
      currentPage: 1,
    }));
  };

  useEffect(() => {
    fetchGameSettingsList();
  }, [fetchGameSettingsList]);

  const columns = useGameSettingsColumn();

  return (
    <PageContainer title="All Game Settings">
      <ProTable
        tooltip="There can only be 1 Setting for a Game Type"
        rowKey="id"
        request={({ pageSize }, filter) => {
          setPagination((prevState) => ({
            ...prevState,
            pageSize,
          }));
          return {
            filter,
          };
        }}
        onSubmit={(params) => handleSubmitFilter(params)}
        onReset={handleResetFilter}
        // onRow={(record) => {
        //   return {
        //     onClick: () => {
        //       navigate(`${record?.id}`);
        //     },
        //   };
        // }}
        scroll={{ x: 800, y: 500 }}
        columns={columns}
        dataSource={gameSettings}
        pagination={{
          pageSize: pagination.pageSize,
          total: pagination.totalItems,
          onChange: (page) =>
            setPagination((prevState) => ({
              ...prevState,
              currentPage: page,
            })),
        }}
        toolBarRender={() => [
          <Button
            key="button"
            icon={<PlusOutlined />}
            onClick={() => {
              navigate("/game-settings/new");
            }}
            type="primary"
          >
            New Game Setting
          </Button>,
        ]}
        search={{
          form: form,
          labelWidth: "auto",
        }}
        // search={false}
      ></ProTable>
    </PageContainer>
  );
};

export default GameSettings;
