import {
  ProForm,
  ProFormText,
  PageContainer,
  ProFormDigit,
  ProFormSwitch,
} from "@ant-design/pro-components";
import { useNavigate } from "react-router";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Form, notification } from "antd";
import { useForm } from "antd/lib/form/Form";
import { getQrCodesData, patchQrCodes } from "../../../api";

const EditQrCode = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = useForm();

  const [qrCodeData, setQrCodeData] = useState();

  const values = Form.useWatch([], form);

  const fetchQrCodesData = useCallback(async () => {
    try {
      const response = await getQrCodesData(id);

      setQrCodeData(response);
    } catch (error) {
      console.log("Error", error);
    }
  }, [id]);

  const handleSubmit = async (values) => {
    try {
      const newParams = {
        ...values,
      };

      const response = await patchQrCodes(id, newParams);

      if (response.status === 200) {
        notification.success({ message: "Update QR code successful" });
        navigate("/qr-codes");
      }
    } catch (error) {
      notification.error({ message: "Update QR code failed" });
      console.log("Error", error);
    }
  };

  useEffect(() => {
    fetchQrCodesData();
  }, [fetchQrCodesData]);

  return (
    <PageContainer title="Edit QR Code">
      {qrCodeData && (
        <ProForm
          form={form}
          initialValues={{
            name: qrCodeData?.name,
            descriptionEn: qrCodeData?.descriptionEn,
            descriptionAr: qrCodeData?.descriptionAr,
            point: qrCodeData?.point,
            maxScanPerPhone: qrCodeData?.maxScanPerPhone,
            isInfiniteClaim: qrCodeData?.isInfiniteClaim,
            isActive: qrCodeData?.isActive,
            maxScanTime: qrCodeData?.isInfiniteClaim
              ? undefined
              : qrCodeData?.maxScanTime,
            titleAr: qrCodeData?.titleAr,
            titleEn: qrCodeData?.titleEn,
          }}
          name="editQrForm"
          layout="horizontal"
          onFinish={handleSubmit}
        >
          <ProForm.Group>
            <div className="form-fields-wrapper">
              <ProFormText
                name="name"
                width={"lg"}
                label="Qr Name"
                placeholder="Enter Qr name in Arabic"
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Qr name is required! My friends
                      </p>
                    ),
                  },
                ]}
              />

              <ProFormText
                name="titleAr"
                width={"lg"}
                label="Title Ar"
                placeholder="Enter Title Ar"
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Title Ar is required! My friends
                      </p>
                    ),
                  },
                ]}
              />
              <ProFormText
                name="titleEn"
                width={"lg"}
                label="Title En"
                placeholder="Enter Title En"
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Title En is required! My friends
                      </p>
                    ),
                  },
                ]}
              />

              <ProFormText
                name="descriptionAr"
                width={"lg"}
                label="Qr Description Ar"
                placeholder="Enter Qr Description"
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Qr Description Ar is required! My friends
                      </p>
                    ),
                  },
                ]}
              />

              <ProFormText
                name="descriptionEn"
                width={"lg"}
                label="Qr Description En"
                placeholder="Enter Qr Description"
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Qr Description En is required! My friends
                      </p>
                    ),
                  },
                ]}
              />

              <ProFormDigit
                name="point"
                label="Point Amount"
                width={"md"}
                min={0}
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Point Amount is required! My friends
                      </p>
                    ),
                  },
                ]}
                fieldProps={{ controls: false }}
              />

              <ProFormDigit
                name="maxScanPerPhone"
                label="Max Scan Per Phone"
                width={"md"}
                min={0}
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Max Scan Per Phone is required! My friends
                      </p>
                    ),
                  },
                ]}
                fieldProps={{ controls: false }}
              />

              {!values?.isInfiniteClaim && (
                <ProFormDigit
                  name="maxScanTime"
                  label="Max Scan Time"
                  width={"md"}
                  min={0}
                  rules={[
                    {
                      required: true,
                      message: () => (
                        <p className="error-message">
                          Max Scan Time is required! My friends
                        </p>
                      ),
                    },
                  ]}
                  fieldProps={{ controls: false }}
                />
              )}

              <ProFormSwitch name="isInfiniteClaim" label="Infinite Claim" />
              <ProFormSwitch name="isActive" label="Active Status" />
            </div>
          </ProForm.Group>
        </ProForm>
      )}
    </PageContainer>
  );
};

export default EditQrCode;
