export const USER_ROLES = [
  {
    value: "OPERATOR",
    label: "Operator",
  },
  {
    value: "USER",
    label: "User",
  },
  {
    value: "GATE_OPERATOR",
    label: "Gate Operator",
  },
  {
    value: "EVENT_OPERATOR",
    label: "Event Operator",
  },
];

export const GENDERS = [
  {
    value: "MALE",
    label: "Male",
  },
  {
    value: "FEMALE",
    label: "Female",
  },
];

export const STATUS_OPTIONS = [
  {
    label: "WAIT_FOR_CHECKIN",
    value: "WAIT_FOR_CHECKIN",
  },
  {
    label: "CHECKED_IN",
    value: "CHECKED_IN",
  },
  {
    label: "CANCELED",
    value: "CANCELED",
  },
  {
    label: "EXPIRED",
    value: "EXPIRED",
  },
];

export const MATCH_STATUSES = [
  // {
  //   label: "SOLD_OUT",
  //   value: "SOLD_OUT",
  // },
  {
    label: "IN_PROGRESS",
    value: "IN_PROGRESS",
  },
  // {
  //   label: "OPEN_FOR_TICKET",
  //   value: "OPEN_FOR_TICKET",
  // },
  {
    label: "END",
    value: "END",
  },
];

export const PAYMENT_OPTIONS = [
  // {
  //   label: "PAYTAP",
  //   value: "PAYTAP",
  // },
  {
    label: "PAYFORT",
    value: "PAYFORT",
  },
];

export const TICKET_TYPE_STATUSES = [
  {
    label: "PUBLISHED",
    value: "PUBLISHED",
  },
  {
    label: "DRAFT",
    value: "DRAFT",
  },
];

// new constant
export const SPORT = "SPORT";
export const CONCERT = "CONCERT";
export const FOOD_COURT = "FOOD_COURT";

export const EVENT_TYPES = [SPORT, CONCERT, FOOD_COURT];

export const EVENT_STATUSES = [
  { value: "WAIT_FOR_TICKET", label: "WAIT_FOR_TICKET" },
  { value: "SOLD_OUT", label: "SOLD_OUT" },
  { value: "OPEN_FOR_TICKET", label: "OPEN_FOR_TICKET" },
  { value: "ENDED", label: "ENDED" },
];
export const ORDER_STATUS = [
  "PENDING",
  "FAILURE",
  "SUCCESS",
  "CANCELED",
  "LATE_PAYMENT",
];
export const ORDER_PAYMENT_OPTIONS = ["VISA", "STCPAY", "MADA", "MASTERCARD"];
export const TICKET_STATUS = [
  "WAIT_FOR_CHECKIN",
  "CHECKED_IN",
  "EXPIRED",
  "CANCELED",
  "LATE_PAYMENT",
];
export const TICKET_PAYMENT_STATUS = ["WAITING_FOR_PAYMENT", "SOLD", "EXPIRED"];
export const GATE_OPERATOR = "GATE_OPERATOR";
export const EVENT_OPERATOR = "EVENT_OPERATOR";
export const CHECK_IN = "CHECK_IN";
export const EVENT_MANAGE = "EVENT_MANAGE";
export const ADD = "ADD";
export const EDIT = "EDIT";
export const REMOVE = "REMOVE";
export const EVENT_ROLES = [
  { value: "EVENT_MANAGE", label: "EVENT_OPERATOR" },
  { value: "CHECK_IN", label: "GATE_OPERATOR" },
];
export const COLORS = [
  {
    value: "#EB2F96/#FFF0F6",
    textColor: "#EB2F96",
    backgroundColor: "#FFF0F6",
    label: "Magenta",
  },
  {
    value: "#FFFFFF/#EB2F96",
    backgroundColor: "#EB2F96",
    textColor: "#FFFFFF",
    label: "Magenta primary",
  },
  {
    value: "#F5222D/#FFF1F0",
    backgroundColor: "#FFF1F0",
    textColor: "#F5222D",
    label: "Red",
  },
  {
    value: "#FFFFFF/#F5222D",
    backgroundColor: "#F5222D",
    textColor: "#FFFFFF",
    label: "Red primary",
  },
  {
    value: "#FA541C/#FFE5D4",
    backgroundColor: "#FFE5D4",
    textColor: "#FA541C",
    label: "Volcano",
  },
  {
    value: "#FFFFFF/#FA541C",
    backgroundColor: "#FA541C",
    textColor: "#FFFFFF",
    label: "Volcano primary",
  },
  {
    value: "#FA8C16/#FFF3DD",
    backgroundColor: "#FFF3DD",
    textColor: "#FA8C16",
    label: "Orange ",
  },
  {
    value: "#FFFFFF/#FA8C16",
    backgroundColor: "#FA8C16",
    textColor: "#FFFFFF",
    label: "Orange primary",
  },
  {
    value: "#F4B000/#FFF4C7",
    backgroundColor: "#FFF4C7",
    textColor: "#F4B000",
    label: "Gold",
  },
  {
    value: "#FFFFFF/#FFC700",
    backgroundColor: "#FFC700",
    textColor: "#FFFFFF",
    label: "Gold primary",
  },
  {
    value: "#A0D911/#FCFFE6",
    backgroundColor: "#FCFFE6",
    textColor: "#A0D911",
    label: "Lime",
  },
  {
    value: "#FFFFFF/#A0D911",
    backgroundColor: "#A0D911",
    textColor: "#FFFFFF",
    label: "Lime primary",
  },
  {
    value: "#1EAF61/#F6FFED",
    backgroundColor: "#F6FFED",
    textColor: "#1EAF61",
    label: "Green",
  },
  {
    value: "#FFFFFF/#1EAF61",
    backgroundColor: "#1EAF61",
    textColor: "#FFFFFF",
    label: "Green primary",
  },
  {
    value: "#13C2C2/#E6FFFB",
    backgroundColor: "#E6FFFB",
    textColor: "#13C2C2",
    label: "Cyan",
  },
  {
    value: "#FFFFFF/#13C2C2",
    backgroundColor: "#13C2C2",
    textColor: "#FFFFFF",
    label: "Cyan primary",
  },
  {
    value: "#127ACE/#EBF5FD",
    backgroundColor: "#EBF5FD",
    textColor: "#127ACE",
    label: "Blue",
  },
  {
    value: "#FFFFFF/#399DEE",
    backgroundColor: "#399DEE",
    textColor: "#FFFFFF",
    label: "Blue primary",
  },
  {
    value: "#2F54EB/#F0F5FF",
    backgroundColor: "#F0F5FF",
    textColor: "#2F54EB",
    label: "Greek blue",
  },
  {
    value: "#FFFFFF/#2F54EB",
    backgroundColor: "#2F54EB",
    textColor: "#FFFFFF",
    label: "Greek blue primary",
  },
  {
    value: "#722ED1/#F9F0FF",
    backgroundColor: "#F9F0FF",
    textColor: "#722ED1",
    label: "Purple",
  },
  {
    value: "#FFFFFF/#722ED1",
    backgroundColor: "#722ED1",
    textColor: "#FFFFFF",
    label: "Purple primary",
  },
];

export const PAGINATION_INITIAL_VALUES = {
  currentPage: 1,
  pageSize: 10,
  totalItems: 0,
};

export const PAGES = {
  SPONSOR: "sponsor",
};

const additionalColors = [
  {
    value: "#FF5733/#FFF4F0",
    backgroundColor: "#FFF4F0",
    textColor: "#FF5733",
    label: "Dark salmon",
  },
  {
    value: "#FFC300/#FFF9E6",
    backgroundColor: "#FFF9E6",
    textColor: "#FFC300",
    label: "Saffron",
  },
  {
    value: "#FFD700/#FFFFE6",
    backgroundColor: "#FFFFE6",
    textColor: "#FFD700",
    label: "Gold",
  },
  {
    value: "#32CD32/#F0FFF0",
    backgroundColor: "#F0FFF0",
    textColor: "#32CD32",
    label: "Lime green",
  },
  {
    value: "#00CED1/#E0FFFF",
    backgroundColor: "#E0FFFF",
    textColor: "#00CED1",
    label: "Dark turquoise",
  },
  {
    value: "#4169E1/#E6E6FF",
    backgroundColor: "#E6E6FF",
    textColor: "#4169E1",
    label: "Royal blue",
  },
  {
    value: "#8A2BE2/#F0E6FF",
    backgroundColor: "#F0E6FF",
    textColor: "#8A2BE2",
    label: "Blue violet",
  },
  {
    value: "#9932CC/#F5E1FF",
    backgroundColor: "#F5E1FF",
    textColor: "#9932CC",
    label: "Dark orchid",
  },
  {
    value: "#FF1493/#FFE6F1",
    backgroundColor: "#FFE6F1",
    textColor: "#FF1493",
    label: "Deep pink",
  },
  {
    value: "#FF4500/#FFEEE6",
    backgroundColor: "#FFEEE6",
    textColor: "#FF4500",
    label: "Orange red",
  },
  {
    value: "#FF8C00/#FFF5E6",
    backgroundColor: "#FFF5E6",
    textColor: "#FF8C00",
    label: "Dark orange",
  },
  {
    value: "#FF6347/#FFECE6",
    backgroundColor: "#FFECE6",
    textColor: "#FF6347",
    label: "Tomato",
  },
  {
    value: "#48D1CC/#E0FFFF",
    backgroundColor: "#E0FFFF",
    textColor: "#48D1CC",
    label: "Medium turquoise",
  },
  {
    value: "#00BFFF/#E6F7FF",
    backgroundColor: "#E6F7FF",
    textColor: "#00BFFF",
    label: "Deep sky blue",
  },
  {
    value: "#1E90FF/#E6EEFF",
    backgroundColor: "#E6EEFF",
    textColor: "#1E90FF",
    label: "Dodger blue",
  },
  {
    value: "#9370DB/#F0E6FF",
    backgroundColor: "#F0E6FF",
    textColor: "#9370DB",
    label: "Medium purple",
  },
  {
    value: "#800080/#F5E1FF",
    backgroundColor: "#F5E1FF",
    textColor: "#800080",
    label: "Purple",
  },
  {
    value: "#4B0082/#F0E6FF",
    backgroundColor: "#F0E6FF",
    textColor: "#4B0082",
    label: "Indigo",
  },
  {
    value: "#8B0000/#FFE6E6",
    backgroundColor: "#FFE6E6",
    textColor: "#8B0000",
    label: "Dark red",
  },
  {
    value: "#FF00FF/#FFE6FF",
    backgroundColor: "#FFE6FF",
    textColor: "#FF00FF",
    label: "Magenta",
  },
];

export const TICKET_COLORS = [...COLORS, ...additionalColors];

export const EVENT_CATAGORIES = {
  SPORT: {
    text: "SPORT",
  },
  CONCERT: {
    text: "CONCERT",
  },
  FOOD_COURT: {
    text: "FOOD COURT",
  },
};

export const SHUKAH_ORDER_STATUSES = [
  {
    title: "InitialOrder",
    value: "initial_order",
  },
  {
    title: "Decline",
    value: "decline",
  },
  {
    title: "Paid",
    value: "paid",
  },
  {
    title: "PaymentFailed",
    value: "payment_failed",
  },
  {
    title: "CitcDeclined",
    value: "citc_declined",
  },
  {
    title: "Pending",
    value: "pending",
  },
  {
    title: "Accepted",
    value: "accepted",
  },
  {
    title: "Unassigned",
    value: "unassigned",
  },
  {
    title: "Acknowledged",
    value: "acknowledged",
  },
  {
    title: "Started",
    value: "started",
  },
  {
    title: "Inprogress",
    value: "inprogress",
  },
  {
    title: "Delivered",
    value: "delivered",
  },
  {
    title: "Successful",
    value: "successful",
  },
  {
    title: "Cancelled",
    value: "cancelled",
  },
  {
    title: "Failed",
    value: "failed",
  },
  {
    title: "Expired",
    value: "expired",
  },
  {
    title: "PreparedOrder",
    value: "prepared_order",
  },
  {
    title: "Assigned",
    value: "assigned",
  },
  {
    title: "Rejected",
    value: "rejected",
  },
  {
    title: "ArrivedStore",
    value: "arrived_store'",
  },
  {
    title: "TakeOrder",
    value: "take_order'",
  },
  {
    title: "WaitCustomer",
    value: "wait_customer'",
  },
];

export const GAME_TYPE_LIST = [
  { label: "Find Word", value: "find_word" },
  { label: "Color Puzzle", value: "color_puzzle" },
  { label: "Flip Cards", value: "flip_cards" },
];
