import { Button, Typography } from "antd";
import { FileTextFilled, EditFilled } from "@ant-design/icons";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;

export const useGameSettingsColumn = () => {
  const navigate = useNavigate();

  return [
    {
      key: "titleAr",
      title: "Title AR",
      dataIndex: "titleAr",
      search: false,
    },
    {
      key: "titleEn",
      title: "Title EN",
      dataIndex: "titleEn",
      search: false,
    },
    {
      key: "gameType",
      title: "Game Type",
      dataIndex: "gameType",
      valueEnum: {
        find_word: {
          text: "Find Word",
        },
        color_puzzle: {
          text: "Color Puzzle",
        },
        flip_cards: {
          text: "Flip Cards",
        },
      },
    },
    {
      key: "isEnabled",
      title: "Is Enabled",
      dataIndex: "isEnabled",
      valueEnum: {
        true: {
          text: "True",
        },
        false: {
          text: "False",
        },
      },
    },
    {
      key: "maxPoints",
      title: "Max Points",
      dataIndex: "maxPoints",
      search: false,
    },
    {
      key: "minLevel",
      title: "Min Level",
      dataIndex: "minLevel",
      search: false,
    },
    {
      key: "pointsPerLevel",
      title: "Points Per Level",
      dataIndex: "pointsPerLevel",
      search: false,
    },

    {
      key: "createdAt",
      title: "Created At",
      dataIndex: "createdAt",
      valueType: "dateTimeRange",
      search: false,
      fieldProps: {
        placeholder: ["Start time", "End time"],
        showTime: { format: "HH:mm" },
      },
      render: (_, record) => {
        return (
          <Text>
            {dayjs(record.createdAt)
              .tz("Asia/Kuwait")
              .format("YYYY/MM/DD HH:mm")
              .toString()}
          </Text>
        );
      },
    },
    {
      key: "updatedAt",
      title: "Updated At",
      dataIndex: "updatedAt",
      hideInTable: true,
      search: false,
      render: (_, record) => {
        return <Text>{dayjs(record.updatedAt).fromNow()}</Text>;
      },
    },
    {
      key: "actions",
      title: "Actions",
      search: false,
      width: 100,
      render: (_, record) => [
        <div key={record.id} className="actions-wrapper-double-items">
          <Button
            className="icon-button"
            icon={<FileTextFilled />}
            onClick={() => navigate(`/game-settings/${record?.id}`)}
          />

          <Button
            className="icon-button"
            icon={<EditFilled />}
            onClick={() => navigate(`/game-settings/${record?.id}/edit`)}
          />
        </div>,
      ],
    },
  ];
};
