import {
  ProForm,
  ProFormText,
  PageContainer,
  ProFormDigit,
  ProFormSwitch,
} from "@ant-design/pro-components";
import { useNavigate } from "react-router";
import { Form, notification } from "antd";
import { useForm } from "antd/lib/form/Form";
import { postQrCodes } from "../../../api";

const NewQrCode = () => {
  const navigate = useNavigate();
  const [form] = useForm();

  const values = Form.useWatch([], form);

  const handleSubmit = async (values) => {
    try {
      const newParams = { ...values };

      const response = await postQrCodes(newParams);

      if (response.status === 201) {
        notification.success({ message: "Create Qr Codes successful" });
        navigate("/qr-codes");
      }
    } catch (error) {
      notification.error({ message: "Create Qr Codes failed" });
      console.log("Error", error);
    }
  };

  return (
    <PageContainer title="Create new Qr Code">
      <ProForm
        form={form}
        name="createQrForm"
        layout="horizontal"
        onFinish={handleSubmit}
      >
        <ProForm.Group>
          <div className="form-fields-wrapper">
            <ProFormText
              name="name"
              width={"lg"}
              label="Qr Name"
              placeholder="Enter Qr name"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Qr name is required! My friends
                    </p>
                  ),
                },
              ]}
            />

            <ProFormText
              name="titleAr"
              width={"lg"}
              label="Title Ar"
              placeholder="Enter Title Ar"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Title Ar is required! My friends
                    </p>
                  ),
                },
              ]}
            />
            <ProFormText
              name="titleEn"
              width={"lg"}
              label="Title En"
              placeholder="Enter Title En"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Title En is required! My friends
                    </p>
                  ),
                },
              ]}
            />

            <ProFormText
              name="descriptionAr"
              width={"lg"}
              label="Qr Description Ar"
              placeholder="Enter Qr Description"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Qr Description is required! My friends
                    </p>
                  ),
                },
              ]}
            />

            <ProFormText
              name="descriptionEn"
              width={"lg"}
              label="Qr Description En"
              placeholder="Enter Qr Description"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Qr Description is required! My friends
                    </p>
                  ),
                },
              ]}
            />

            <ProFormDigit
              name="point"
              label="Point Amount"
              width={"md"}
              min={0}
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Point is required! My friends
                    </p>
                  ),
                },
              ]}
              fieldProps={{ controls: false }}
            />

            <ProFormDigit
              name="maxScanPerPhone"
              label="Max Scan Per Phone"
              width={"md"}
              min={0}
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Max Scan Per Phone is required! My friends
                    </p>
                  ),
                },
              ]}
              fieldProps={{ controls: false }}
            />

            {!values?.isInfiniteClaim && (
              <ProFormDigit
                name="maxScanTime"
                label="Max Scan Time"
                width={"md"}
                min={0}
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Max Scan Time is required! My friends
                      </p>
                    ),
                  },
                ]}
                fieldProps={{ controls: false }}
              />
            )}

            <ProFormSwitch
              name="isInfiniteClaim"
              label="Infinite Claim"
              initialValue={false}
            />

            <ProFormSwitch
              name="isActive"
              label="Active Status"
              initialValue={false}
            />
          </div>
        </ProForm.Group>
      </ProForm>
    </PageContainer>
  );
};

export default NewQrCode;
