import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
// import Home from "./screens/Home";
import Login from "./screens/Login";
import Clubs from "./screens/Clubs";
import Events from "./screens/Events";
import EventDetails from "./screens/Events/EventDetails";
import NewEvent from "./screens/Events/NewEvent";
import EditEvent from "./screens/Events/EditEvent";
import SubCategories from "./screens/SubCategories";
import SubCategory from "./screens/SubCategories/SubCategoryDetails";
import NewSubCategory from "./screens/SubCategories/NewSubCategory";
import EditSubCategory from "./screens/SubCategories/EditSubCategory";
import TicketDetails from "./screens/Events/Tickets/TicketDetails";
import CheckInHistories from "./screens/CheckInHistories";
import Permissions from "./screens/Events/Permissions";
import NewClub from "./screens/Clubs/NewClub";
import PrivateRoute from "./components/PrivateRoute";
import { ConfigProvider, Modal } from "antd";
import useLayout from "./utils/useLayout";
import enUSIntl from "antd//lib/locale/en_US";
import Users from "./screens/Users";
import NewUser from "./screens/Users/NewUser";
import ClubDetails from "./screens/Clubs/ClubDetails";
import EditClub from "./screens/Clubs/EditClub";
import UserDetails from "./screens/Users/UserDetails";
import EditUser from "./screens/Users/EditUser";
import OTPs from "./screens/OTPs";
import { ProConfigProvider, ProLayout } from "@ant-design/pro-components";
import { useCallback, useEffect, useState } from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import menuData from "./utils/menuData";
import Tickets from "./screens/Events/Tickets";
import Sliders from "./screens/Settings/Sliders";
import NewSlider from "./screens/Settings/Sliders/NewSlider";
import EditSlider from "./screens/Settings/Sliders/EditSlider";
import SliderDetails from "./screens/Settings/Sliders/SliderDetails";
import System from "./screens/Settings/System";
import EventOrders from "./screens/Events/Orders";
import Sponsors from "./screens/Sponsors";
import SponsorDetails from "./screens/Sponsors/SponsorDetails";
import NewSponsor from "./screens/Sponsors/NewSponsor";
import EditSponsor from "./screens/Sponsors/EditSponsor";
import AssignSponsors from "./screens/Events/Sponsors";
import PaymentLogs from "./screens/PaymentLogs";
import NewTicketType from "./screens/Events/TicketTypeDetails/NewTicketType";
import EditEventOrder from "./screens/Events/Orders/EditOrder";
import EditEventTicket from "./screens/Events/Tickets/EditTicket";
import OrderLogs from "./screens/OrderLogs";
import Odoo from "./screens/Settings/Odoo";
import Coupon from "./screens/Coupon";
import CouponNew from "./screens/Coupon/CouponNew";
import CouponDetail from "./screens/Coupon/CouponDetail";
import CouponEdit from "./screens/Coupon/CouponEdit";
// import Stadiums from "./screens/Stadiums";
// import NewStadium from "./screens/Stadiums/NewStadium";
// import EditStadium from "./screens/Stadiums/EditStadium";
import { getProfile } from "./api";
import QrCodes from "./screens/QrCodes";
import QrCodeDetails from "./screens/QrCodes/QrCodesDetails";
import NewQrCode from "./screens/QrCodes/NewQrCode";
import EditQrCode from "./screens/QrCodes/EditQrCode";
import EventGame from "./screens/Settings/EventGame";
import GameSettings from "./screens/GameSettings";
import GameSettingsDetail from "./screens/GameSettings/GameSettingsDetail";
import NewGameSettings from "./screens/GameSettings/NewGameSettings";
import EditGameSettings from "./screens/GameSettings/EditGameSettings";

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const newMenuData = token ? menuData : {};

  const [isShowModal, setIsShowModal] = useState(false);

  const fetchProfile = useCallback(
    async (token) => {
      try {
        const profile = await getProfile(token);

        if (profile?.roles !== "OPERATOR") {
          localStorage.removeItem("token");
          navigate("/login");
          return;
        }
      } catch (error) {
        console.log("🚀 👻 -> fetchProfile -> error:", error);
      }
    },
    [navigate]
  );

  useEffect(() => {
    if (token) fetchProfile(token);
  }, [token, fetchProfile]);

  const WithLayoutRoutes = useLayout(() => (
    <PrivateRoute>
      <Routes>
        {/* clubs */}
        <Route path="/clubs" element={<Clubs />} />
        <Route path="/clubs/new" exact element={<NewClub />} />
        <Route path="/clubs/:id" exact element={<ClubDetails />} />
        <Route path="/clubs/:id/edit" exact element={<EditClub />} />
        {/* Stadium */}
        {/* <Route path="/stadiums" element={<Stadiums />} />
        <Route path="/stadiums/new" exact element={<NewStadium />} />
        <Route path="/stadiums/:id/edit" exact element={<EditStadium />} /> */}
        {/*
        {/* events */}
        <Route path="/events" element={<Events />} />
        <Route path="/events/new" exact element={<NewEvent />} />
        <Route path="/events/:id" exact element={<EventDetails />} />
        <Route path="/events/:id/edit" exact element={<EditEvent />} />
        {/* coupons */}
        <Route path="/coupon" exact element={<Coupon />} />
        <Route path="/coupon/new" exact element={<CouponNew />} />
        <Route path="/coupon/:idCoupon" exact element={<CouponDetail />} />
        <Route path="/coupon/edit/:idCoupon" exact element={<CouponEdit />} />
        {/* event ticket types */}
        <Route
          path="/events/:id/ticket-types/new"
          exact
          element={<NewTicketType />}
        />
        {/* event permission */}
        <Route path="/events/:id/permission" exact element={<Permissions />} />
        {/* event orders */}
        <Route path="/events/:id/orders" exact element={<EventOrders />} />
        <Route path="/orders/:id/edit" exact element={<EditEventOrder />} />
        {/* event tickets */}
        <Route path="events/:id/tickets" exact element={<Tickets />} />
        <Route path="/tickets/:id" exact element={<TicketDetails />} />
        <Route path="/tickets/:id/edit" exact element={<EditEventTicket />} />
        {/* event sponsors */}
        <Route
          path="events/:id/assign-sponsors"
          exact
          element={<AssignSponsors />}
        />
        {/* sub categories */}
        <Route path="/sub-categories" element={<SubCategories />} />
        <Route path="/sub-categories/new" exact element={<NewSubCategory />} />
        <Route
          path="/sub-categories/:id/edit"
          exact
          element={<EditSubCategory />}
        />
        <Route path="/sub-categories/:id" exact element={<SubCategory />} />
        {/* check in histories */}
        <Route
          path="events/:id/check-in-histories"
          element={<CheckInHistories />}
        />
        {/* sponsors */}
        <Route path="sponsors" element={<Sponsors />} />
        <Route path="sponsors/:id" element={<SponsorDetails />} />
        <Route path="sponsors/new" element={<NewSponsor />} />
        <Route path="sponsors/:id/edit" element={<EditSponsor />} />
        {/* settings */}
        <Route path="/sliders" element={<Sliders />} />
        <Route path="/sliders/new" element={<NewSlider />} />
        <Route path="/sliders/:id/edit" element={<EditSlider />} />
        <Route path="/sliders/:id" element={<SliderDetails />} />
        <Route path="/system" exact element={<System />} />
        <Route path="/odoo" exact element={<Odoo />} />
        <Route path="/event-game" exact element={<EventGame />} />
        {/* users */}
        <Route path="/users" exact element={<Users />} />
        <Route path="/users/new" exact element={<NewUser />} />
        <Route path="/users/:id" exact element={<UserDetails />} />
        <Route path="/users/:id/edit" exact element={<EditUser />} />
        {/* OTP */}
        <Route path="/sms-otp" exact element={<OTPs />} />
        {/* Payment logs */}
        <Route path="/payment-logs" exact element={<PaymentLogs />} />
        {/* Order logs */}
        <Route path="/order-logs" exact element={<OrderLogs />} />
        {/* QR Codes */}
        <Route path="/qr-codes" exact element={<QrCodes />} />
        <Route path="qr-codes/:id" element={<QrCodeDetails />} />
        <Route path="qr-codes/new" element={<NewQrCode />} />
        <Route path="qr-codes/:id/edit" element={<EditQrCode />} />
        {/* Game Settings */}
        <Route path="/game-settings" exact element={<GameSettings />} />
        <Route path="game-settings/:id" element={<GameSettingsDetail />} />
        <Route path="game-settings/new" element={<NewGameSettings />} />
        <Route path="game-settings/:id/edit" element={<EditGameSettings />} />
      </Routes>
    </PrivateRoute>
  ));

  const handleCancel = () => setIsShowModal(false);

  const handleSubmitModal = () => {
    localStorage.removeItem("token");
    navigate("/login");

    handleCancel();
  };

  return (
    <ConfigProvider locale={enUSIntl}>
      <ProConfigProvider hashed={false}>
        <ProLayout
          title=""
          layout="mix"
          menuItemRender={(item, dom) => {
            return (
              <div
                onClick={() => {
                  if (item.path === "/logout") {
                    setIsShowModal(true);
                  } else {
                    navigate(item.path);
                  }
                }}
                className={
                  item.path !== location.pathname ? "menu-items" : "menu-active"
                }
                style={{
                  color: item.path === location.pathname ? "white" : "black",
                  backgroundColor:
                    item.path === location.pathname ? "#3390ff" : "transparent",
                }}
              >
                {dom}
              </div>
            );
          }}
          logo={`../../logo.png`}
          suppressSiderWhenMenuEmpty={true}
          {...newMenuData}
        >
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="*" exact element={<WithLayoutRoutes />} />
          </Routes>
        </ProLayout>
      </ProConfigProvider>

      <Modal
        open={isShowModal}
        onOk={() => handleSubmitModal()}
        onCancel={handleCancel}
        centered
      >
        <div className="delete-content">
          <ExclamationCircleFilled style={{ fontSize: "48px" }} />
          <p>Are you sure to logout?</p>
        </div>
      </Modal>
    </ConfigProvider>
  );
};

export default App;
